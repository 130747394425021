<template>
    <div v-if="data != ''">
        <a-page-header title="返回" subTitle="资料详情" @back="() => $router.go(-1)" />

        <a-row style="background-color: #fff;">
            <a-col :span="4"></a-col>
            <a-col :span="12">
                <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                    <a-form-item label="资料名称">
                        {{ data.title }}
                    </a-form-item>
                    <a-form-item label="上传视频">
                        <a-table :columns="videoColumns" :data-source="data.videos.list" rowKey="id" :pagination="false">
                            <!-- <a slot="name" slot-scope="text">{{ text }}</a>
                            <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
                            <span slot="tags" slot-scope="tags">
                                <a-tag v-for="tag in tags" :key="tag"
                                    :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'">
                                    {{ tag.toUpperCase() }}
                                </a-tag>
                            </span>
                            <span slot="action" slot-scope="text, record">
                                <a>Invite 一 {{ record.name }}</a>
                                <a-divider type="vertical" />
                                <a>Delete</a>
                                <a-divider type="vertical" />
                                <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a>
                            </span> -->
                        </a-table>



                        <span>
                            <a v-for="(item, index) in data.videos.list" :key="'videos' + index" style="margin-right: 10px;"
                                @click="selectVideo(index)">
                                {{ item.title }}
                            </a>
                        </span>
                    </a-form-item>
                    <a-form-item label="上传文件">
                        <span>
                            <a style="margin-right: 10px;" v-for="(item, index) in data.files.list" :key="'files' + index"
                                @click="download(item)">
                                {{ item.title }}
                            </a>
                        </span>
                    </a-form-item>
                </a-form>
            </a-col>
            <a-col :span="6">
                <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
                    <a-form-item label="审核员">
                        {{ data.admin_name }}
                    </a-form-item>
                    <a-form-item label="审核时间">
                        <span v-if="data.check_time != 0">
                            {{ moment(Number(data.check_time + "000")).format("YYYY-MM-DD HH:mm:ss") }}
                        </span>
                    </a-form-item>
                    <a-form-item label="审核状态">
                        <a-tag color="#999" v-if="data.check_status == 1">
                            待审核
                        </a-tag>
                        <a-tag color="blue" v-else-if="data.check_status == 2">
                            已确认
                        </a-tag>
                        <a-tag color="red" v-else-if="data.check_status == 3">
                            已驳回
                        </a-tag>
                        <a-tag color="green" v-else-if="data.check_status == 4">
                            已打款
                        </a-tag>
                    </a-form-item>
                    <a-form-item label="驳回理由" v-if="data.check_status == 3">
                        {{ data.reason }}
                    </a-form-item>

                    <a-form-item label="打款凭证" v-if="data.check_status == 4">
                        <img :src="data.pay_image_url" alt="" width="200" v-if="data.pay_image_url">
                    </a-form-item>
                    <a-form-item label="分值" v-if="data.check_status == 4">
                        {{ data.score }}
                    </a-form-item>
                    <a-form-item label="打款时间" v-if="data.check_status == 4">
                        <span v-if="data.pay_time != 0">
                            {{ moment(Number(data.pay_time + "000")).format("YYYY-MM-DD HH:mm:ss") }}
                        </span>
                    </a-form-item>

                    <a-form-item label="审核" v-if="data.check_status == 1">
                        <a-radio-group v-model="checkData.check_status" @change="checkstatusChange">
                            <a-radio :value="2">
                                通过
                            </a-radio>
                            <a-radio :value="3">
                                不通过
                            </a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="分值" v-if="checkData.check_status == 2 && data.check_status == 1">
                        <a-input-number :min="0" v-model="checkData.score" />
                    </a-form-item>
                    <a-form-item label="驳回原因" v-if="checkData.check_status == 3 && data.check_status == 1">
                        <a-textarea v-model="checkData.reason" :auto-size="{ minRows: 3, maxRows: 6 }" />
                    </a-form-item>


                    <a-form-item v-if="data.check_status == 1" :wrapper-col="{ offset: 8, span: 16 }">
                        <a-button type="primary" @click="submit">提交</a-button>
                    </a-form-item>
                </a-form>

            </a-col>
        </a-row>

        <a-modal v-model:visible="visible" title="视频详情" @ok="handleOk" cancelText="关闭" okText="下载视频">
            <video style="width: 100%;height: 400px;" :src="data.videos.list[selectIndex].file_url" controls
                v-if="data.videos.list.length > 0">
            </video>
        </a-modal>
        <!-- <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }"> -->

    </div>
</template>

<script>
import {
    materialDetail,
    materialCheckl
} from "@/apis/index";
import axios from "axios";
import moment from "moment";
const videoColumns = [
    {
        title: "视频名称",
        dataIndex: "title",
        scopedSlots: { customRender: "title" },
    },
    {
        title: "视频分类",
        dataIndex: "cat_name",
        scopedSlots: { customRender: "cat_name" },
    },
    {
        title: "视频时长",
        dataIndex: "video_time",
        scopedSlots: { customRender: "video_time" },
    },
    {
        title: "默认分值",
        dataIndex: "start_score",
        scopedSlots: { customRender: "start_score" },
    },
    {
        title: "视频状态",
        dataIndex: "status",
        scopedSlots: { customRender: "status" },
        // reason
    },
    {
        title: "最终分值",
        dataIndex: "score",
        scopedSlots: { customRender: "score" },
    },
]


export default {
    data() {
        return {
            videoColumns,
            data: '',
            moment,

            checkData: {
                id: 0,
                check_status: 2,
                score: '',
                reason: ''
            },

            visible: false,

            selectIndex: 0

        }
    },
    created() {
        this.checkData.id = this.$route.query.id
        this.getDetail()
    },
    methods: {
        getDetail() {
            materialDetail({ id: this.$route.query.id }).then((res) => {
                if (res.code == 1) {
                    this.data = res.data;
                }
            });
        },


        submit() {
            materialCheckl(this.checkData).then(res => {
                if (res.code == 1) {
                    this.$message.success("审核成功");
                    // this.$message('提交成功')
                    this.getDetail()
                }
            })
        },

        handleOk() {
            let item = this.data.videos.list[this.selectIndex]
            axios({
                method: 'GET',
                url: item.file_url,
                responseType: 'blob'
            }).then(res => {
                const href = URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = href;
                link.setAttribute('download', item.title);
                document.body.appendChild(link);
                link.click();
            })
        },

        selectVideo(index) {
            this.selectIndex = index
            this.visible = true
        },
        checkstatusChange() {

        },
        download(item) {
            axios({
                method: 'GET',
                url: item.file_url,
                responseType: 'blob'
            }).then(res => {
                const href = URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = href;
                link.setAttribute('download', item.title);
                document.body.appendChild(link);
                link.click();
            })
        }

    }
}
</script>

<style lang="less" scoped></style>